
.dispatch-modalM {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;  
    color: white;
    

  }
  
  .dispatch-modalM.open {
    opacity: 1;
    pointer-events: auto;
  }
  
  .modal-overlayM {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  
  .modal-overlayM.open-overlay {
    background-color: rgba(0, 0, 0, 0.0);
  }
  
  .modal-contentM {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    z-index: 1001;
    transition: transform 0.3s;
    transform: translateY(-50px);
    opacity: 0;
  }
  
  .modal-contentM.open-content {
    transform: translateY(0);
    opacity: 1;
  }
  