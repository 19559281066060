.dispatch-modalM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.dispatch-modalM.open {
  opacity: 1;
  pointer-events: auto;
}

.modal-overlayM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.modal-overlayM.open-overlay {
  background-color: rgba(0, 0, 0, 0.0);
}

.modal-contentM {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  z-index: 1001;
  transition: transform 0.3s;
  transform: translateY(-50px);
  opacity: 0;
}

.modal-contentM.open-content {
  transform: translateY(0);
  opacity: 1;
}

/* Notification Preview Styles */
.notification-preview {
  width: 100%; /* Set to the desired width */
  max-width: 600px; /* Maximum width of notification */
  margin: 0 auto; /* Center the notification */
  padding-right: 30px;
  border-radius: 10px; /* Rounded corners for the notification container */
  overflow: hidden; /* Ensures the content respects the border-radius */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;

}

/* iOS Notification Styles */
.ios-notification {
  background-color: #f9f9f9;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin-top: 10px;
  font-family: 'San Francisco', 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  max-width: 230px;
  width: 230px;
}

.ios-notification img {
  border-radius:25%; /* Rounds the corners of the image */
}

.ios-notification-title {
  font-size: 17px;
  color: #000;
  font-weight: bold;
}

.ios-notification-body {
  font-size: 15px;
  color: #333;
}

/* Android Notification Styles */
.android-notification {
  background-color: #ffffff; /* white background */
  border-radius: 4px; /* Android notifications are typically less rounded than iOS */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 3px rgba(0, 0, 0, 0.12); /* softer shadow */
  padding: 12px; /* closer to native padding */
  margin-bottom: 20px; /* smaller margin */
  font-family: 'Roboto', 'Arial', sans-serif;
  display: flex;
  flex-direction: column; /* Stack header and content vertically */
  width: 100%; /* let it take as much space as needed, or set a max-width */
  align-items: flex-start; /* Aligns items to the start of the cross axis */
  max-width: 230px;
  width: 230px;
}

.android-notification img {
  border-radius: 50%; /* fully round the icon as per native style */
  width: 20px; /* fixed width */
  height: 20px; /* fixed height */
  margin-right: 16px; /* consistent spacing */
  margin-left: 0px;

}

.android-notification-title {
  font-size: 16px;
  color: #000;
  font-weight: 500; /* medium font weight, similar to native */
  flex-grow: 1; /* allow the title to take up available space */
}

.android-notification-body {
  font-size: 14px;
  color: #757575; /* grey color for body, similar to native */
}
.android-notification-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #757575; /* Adjust the color as needed */
  margin-bottom: 8px; /* Space between header and content */
}


.android-notification-app-name {
  margin-right: 4px; /* Small space before the dot */
  margin-left: -5px;

}

.android-notification-dot {
  margin-right: 4px; /* Small space before the bell icon */
}
.android-notification-bell-icon {
  font-size: 13px; /* Adjust icon size as needed */
  color: #757575; /* Adjust icon color as needed */
}

.android-notification-content {
  display: flex;
  flex-direction: column; /* Stack title and body vertically */
  padding-left: 31px;

}

.android-notification-title {
  font-size: 16px;
  color: #000;
  font-weight: 500; /* medium font weight, similar to native */
  margin-bottom: 2px; /* Small space between title and body */
}

.android-notification-body {
  font-size: 14px;
  color: #757575; /* grey color for body, similar to native */
}

.android-notification img,
.ios-notification img {
  max-width: 40px; /* Ensure image is no wider than 40px */
  max-height: 40px; /* Ensure image is no taller than 40px */
  margin-right: 16px;
}
