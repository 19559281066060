/*App.css*/
/* Change background color and text color for light mode */
.App.light-mode {
  background-color: #ffffff; /* Light background color */
  color: #000000; /* Dark text color */
}

/* Remove background color and set text color for light mode */
.App.light-mode .App-header {
  background-color: transparent; /* Remove background color */
  color: #000000; /* Dark text color */
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}


@font-face {
  font-family: 'Roboto-Bold';
  src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}




@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow/Barlow-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsXlight';
  src: url('./fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsLight';
  src: url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
}


@font-face {
  font-family: 'PoppinsBold';
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsSemi';
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}


@font-face {
  font-family: 'NeuePlakBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakRegular';
  src: url('./fonts/neue-plak/Neue\ Plak\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakSemiBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakLight';
  src: url('./fonts/neue-plak/Neue\ Plak\ Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakExtendedBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Extended\ Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakExtendedRegular';
  src: url('./fonts/neue-plak/Neue\ Plak\ Extended\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakExtendedSemiBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Extended\ SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakExtendedLight';
  src: url('./fonts/neue-plak/Neue\ Plak\ Extended\ Light.ttf') format('truetype');
}


@font-face {
  font-family: 'NeuePlakCondensedBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Condensed\ Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakCondensedRegular';
  src: url('./fonts/neue-plak/Neue\ Plak\ Condensed\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakCondensedSemiBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Condensed\ SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakTextSemiBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ Text\ SemiBold.ttf') format('truetype');
}


@font-face {
  font-family: 'NeuePlakThin';
  src: url('./fonts/neue-plak/Neue\ Plak\ Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakRegular';
  src: url('./fonts/neue-plak/Neue\ Plak\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlakSemiBold';
  src: url('./fonts/neue-plak/Neue\ Plak\ SemiBold.ttf') format('truetype');
}



@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Range from ExtraLight to Black */
  font-style: normal;
}

/* Change link color for light mode */
.App.light-mode .App-link {
  color: #007acc; /* Light blue link color */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.spinner-small {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}






@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



@keyframes flashNavy {
  0%, 100% {
    background-color: #1c345d; /* or any color it originally is */
  }
  50% {
    background-color: #08adec;
  }
}

.flash-navy-animation {
  animation: flashNavy 1s infinite; /* Adjust time as needed */
}


@keyframes flashYellow {
  0%, 100% { background-color: #000000; }
  50% { background-color: #FFCC00; }
}

.flash-yellow-animation {
  animation: flashYellow 1s infinite;
}

.live-text {
  animation: flashing 2s infinite; /* Keeps a 3s cycle for smooth fading */
  color: #007ae4;
  font-size: 20px;
  font-family: "PoppinsSemi";
  margin-left: 20px;
}