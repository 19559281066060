
.hero-parallax {
  height: 300vh; /* Ensure this height allows enough space for scrolling */
  position: relative;
  display: flex;
  flex-direction: column;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.parallax-container {
  flex: 1; /* Ensure the container takes up remaining space */
  display: flex;
  flex-direction: column;
  z-index: 1; /* Ensure it stays below the header */
}

.product-row {
  display: flex;
  margin-bottom: 20px;
  min-height: 24rem; /* Ensure rows have a minimum height */
}

.product-row:nth-child(2),
.product-row:nth-child(3) {
  margin-left: -800px; /* Adjust this value as needed */
}

.reverse-row {
  flex-direction: row-reverse;
}

.header {
  max-width: 1120px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: left;
  z-index: 100; /* Ensure it stays on top */
  position: relative; /* Ensure it is positioned correctly */
}

.header-title {
  font-size: 3rem;
  font-weight: bold;
}

.header-description {
  max-width: 720px;
  margin-top: 20px;
  font-size: 1.25rem;
}

.product-card {
  height: 24rem;
  width: 30rem;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  margin: 10px; /* Add margin for gap */
}

.product-link {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}

.product-image {
  object-fit: cover;
  object-position: left top;
  height: 100%;
  width: 100%;
  position: absolute;
}

.product-overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: black;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.product-card:hover .product-overlay {
  opacity: 0.8;
}

.product-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
  opacity: 0;
  color: white;
  transition: opacity 0.3s ease;
}

.product-card:hover .product-title {
  opacity: 1;
}

/* Ensure the HomePin component does not interfere with the parallax effect */
.header-pin-container {
  position: fixed; /* Change from relative to fixed */
  top: 50%; /* Adjust this value to place HomePin in the center vertically */
  left: 50%; /* Adjust this value to place HomePin in the center horizontally */
  transform: translate(-50%, -50%); /* Center HomePin exactly on the screen */
  z-index: 10; /* Ensure it stays above the parallax content */
}