.home-container {
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
  height: 100vh;
  position: relative; /* Add this if not already present */
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    width: 45%;
    max-width: 400px; /* Adjust the maximum width as needed */
    margin: 60px auto 40px auto; /* Added margin-top to create a gap below TopBar */
    border: 2px solid silver; /* Border color */
    border-radius: 0px;
    padding: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional box shadow */
  }
  
  .small-text {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 12px; /* or any small size you prefer */
  }

  .centered-container button.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-container input,
  .centered-container button {
    margin: 10px ;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0px;
    font-size: 16px;
  }
  
  .centered-container button {
    background-color: #06adee;
    border-radius: 25px;
    width: 150px;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
  
  .centered-container button:hover {
    background-color: #7fe0fe;

  }
  
  .form-title {
    display: flex;
    background-image: url('https://target.scene7.com/is/image/Target/settings-bg-tile-light?wid=1200&qlt=60');
    justify-content: center;
    align-items: center;
    width: 100%; 
    padding-top: 20px;
    padding-bottom: 20px;

  }


  .login-title {
    font-size: 36px; /* Adjust the size as needed */
}

.double {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* This ensures that the .double element spans the full width of its container */
  padding: 0 20px; /* Add padding on the sides if needed */
}

.footer {
  display: flex; /* Enables flexbox layout */
  justify-content: space-between; /* Distributes space evenly between items */
  align-items: start; /* Aligns items to the start of the flex container */
  background-color: #131d33; /* Example background color */
  color: white; /* Example text color */
  height: 40vh; /* Adjust the height as needed */  
  padding: 0px; /* Adds some padding inside the footer */
  padding-left: 150px;
  padding-right: 100px;
}

.footer-link{
  padding-top: 100px;
  display: flex; /* Enables flexbox layout within each section */
  flex-direction: column; /* Aligns items vertically */
  justify-content: flex-start; /* Aligns items to the start vertically */
  flex: 0.5; /* Each section takes equal width */
  margin: 0 10px; /* Adds some space between the sections */
}

.footer-link .link-item {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Aligns items vertically in the center */
  margin-bottom: 10px; /* Adds space between each link item */
}

.footer-link .link-item span {
  margin-left: 5px; /* Adds space between the icon and the text */
}


.footer-section {
  padding-top: 100px;
  display: flex; /* Enables flexbox layout within each section */
  flex-direction: column; /* Aligns items vertically */
  justify-content: flex-start; /* Aligns items to the start vertically */
  flex: 1; /* Each section takes equal width */
  margin: 0 0px; /* Adds some space between the sections */
}

.footer-section h3 {
  margin-bottom: 10px; /* Spacing below the section headers */
}
.footer-section p {
  margin-right: 50px; /* Spacing below the section headers */
}

.footer h3 {
  margin-bottom: 20px; /* Removes the default margin */
  padding: 0; /* You can also reset padding if needed */
  /* Add other styles as needed */
}

.footer-link li {
 list-style: none;
}

.footer-bottom {
  text-align: center;
  background-color: #131d33;
  color: #fff;
  padding: 10px 0;
  width: 100%; /* Full width of the footer */
  margin-top: auto; /* Pushes it to the bottom */
}

.navbar {
  background-color: #ffffff;
  margin-right: 60px;
  display: flex;
}

.nav-menu {
  list-style: none;
  background-color: #ffffff;
  display: flex;
  padding-left: 0;
}
.navbar a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits color from parent element */
  font-size: 16px; /* Sets font size */
  /* Add other styles as needed */
}

.navbar a:hover {
  text-decoration: underline; /* Example hover effect */
  color: #06adee; /* Changes color on hover */
}

.nav-menu li {
  padding: 1rem;
  padding-top: 40px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  
}

.nav-menu li.hard-light {
  background-color: #131d33;
  color: #ffffff;
  padding: 10;
  height: 40px;
  }


  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;

  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  