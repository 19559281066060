.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 80%; /* Set width to 60% of the viewport width */
  height: 70vh; /* Set height to 60% of the viewport height */
  border: 3px solid black; /* Add border style here */
  border-radius: 0px; /* Add border radius for rounded corners */
  overflow: hidden;

}

/* Add these styles for the graph bars */
.graph {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 52vh; /* Set the height of the graph container */
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;

}

.bar {
  flex: 1;
  background-color: #3498db; /* Bar color */
  border: 1px solid #2980b9; /* Border color */
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  color: #3498db; /* Text color */
  font-size: 12px; /* Text font size */
  transition: height 0.3s ease; /* Smooth height transition */
}

.bar:hover {
  background-color: #2980b9; /* Hovered bar color */
}


.top-rectangle {
  height: 100px;
  background-color: rgb(255, 255, 255);
  position: relative; /* Add this property */
}

.square {
  height: 100%;
  border: 1px solid #000000; /* Border color */
  display: flex; /* Use Flexbox to center vertically */
  align-items: center; /* Center items vertically */
}

.yellow {
  left: 0;
  width: calc(100% / 3);
  position: absolute; /* Add this property */
}

.purple {
  left: calc(100% / 3);
  width: calc(100% / 3);
  position: absolute; /* Add this property */
}

.pink {
  left: calc(2 * 100% / 3);
  width: calc(100% / 3);
  position: absolute; /* Add this property */
}


.main-grid {
  display: grid;
  grid-template-columns: 3fr 1fr; /* Adjust column proportions */
  flex-grow: 1;
}

.right-rectangle {
  background-color: rgb(255, 255, 255);
  border-left: 2px solid #000000; /* Add a left border */

}

.center-content {
  background-color: rgb(255, 255, 255);
}


.circle {
  margin-top: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(40%, -50%);
  font-size: 24px;
  padding: 50px; /* Adjust padding to control the size of the background circle */
  border-radius: 50%; /* Make the background circle shape */
  aspect-ratio: 1; /* Maintain a 1:1 aspect ratio */

}

.number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  background-color: white; /* Add a background color */
  padding: 10px; /* Adjust padding to control the size of the background circle */
  border-radius: 50%; /* Make the background circle shape */
  aspect-ratio: 1; /* Maintain a 1:1 aspect ratio */

}

/* For a 100% filled circle */
.yellow .circle {

  background: conic-gradient(
    from -135deg, /* Start at -90 degrees (7 o'clock) */
    #3498db 200deg, /* Blue color from 7 o'clock to 5 o'clock */
    transparent 120deg /* Transparent from 5 o'clock to 360 degrees */

  );
}

/* For a 50% filled circle */
.purple .circle {
  background: conic-gradient(
    from -135deg, /* Start at -90 degrees (7 o'clock) */
    #3498db 270deg, /* Blue color from 7 o'clock to 5 o'clock */
    transparent 120deg /* Transparent from 5 o'clock to 360 degrees */
  );
}

/* For a custom percentage filled circle (e.g., 75%) */
.pink .circle {
  background: conic-gradient(
    from -135deg, /* Start at -90 degrees (7 o'clock) */
    #3498db 100deg, /* Blue color from 7 o'clock to 5 o'clock */
    transparent 20deg /* Transparent from 5 o'clock to 360 degrees */
  );
}


.zipcode-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust as needed */
}

.zipcode-text {
  margin-right: 10px; /* Adjust as needed to create space between text and bar */
}


/* Updated styles for the zip code graph */
.zip-code-graph {
  display: flex;
  flex-direction: column; /* Display bars vertically */
  align-items: flex-start; /* Align bars to the left */
  height: 375px; /* Set the height of the zip code graph container */
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.zipcode-bar {
  display: flex; /* Use a flex container */
  justify-content: space-between; /* Put space between zip code number and bar */
  align-items: center; /* Vertically center content */
  background-color: #3498db;
  border: 1px solid #2980b9;
  border-radius: 5px;
  text-align: center;
  color: #3498db;
  font-size: 12px;
  margin-bottom: 10px;
  transition: width 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
}

.zipcode-bar:hover {
  background-color: #2980b9; /* Hovered bar color */
}

.zipcode-label {
  margin-top: 5px;
  font-size: 12px; /* Label font size */
  white-space: nowrap; /* Prevent label from wrapping */
  overflow: hidden; /* Hide overflowing label */
  text-overflow: ellipsis; /* Show ellipsis (...) for long labels */
}

.baseButton {
  font-size: 16px;
  color: white; /* Text color */
  background-color: #007ae4; /* Background color */
  border-radius: 25px; /* Make it round */
  padding: 15px 20px; /* Padding for sizing (adjust as needed) */
  border: none; /* Remove default button border */
  cursor: pointer; /* Change cursor to pointer on hover */
  outline: none; /* Remove focus outline */
  transition: background-color 0.3s; /* Smooth transition for background color */
}
.baseButton:hover {
  background-color: #7fe0fe; /* Darker shade when hovered */
}