/* Pricing.css */

/* Style the pricing list container */
.pricing-container {
    margin-top: 20px;
  }
  
  /* Style each pricing row */
  .pricing-item {
    list-style: none;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 20px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Style the pricing item name */
  .pricing-item-name {
    flex: 1; /* Take up available space */
    font-weight: bold;
  }
  .pricing-item-content {
    display: flex;
    align-items: center;
  }

  
  /* Style the pricing item value */
  .pricing-item-value {
    font-weight: bold;
    font-size: 20px;
    color: #00102b; /* You can use your preferred color */
  }
  

  .pricing-scroll-container {
    max-height: 90vh; /* Set the maximum height for scrollability */
    overflow-y: auto; /* Add a vertical scrollbar when the content exceeds the container's height */
  }
  
  /* Add this style to prevent horizontal scrollbar */
  .pricing-container {
    overflow-x: hidden;
  }

  .baseButton {
    font-size: 16px;
    color: white; /* Text color */
    background-color: black; /* Background color */
    border-radius: 16px; /* Make it round */
    padding: 10px 20px; /* Padding for sizing (adjust as needed) */
    border: none; /* Remove default button border */
    cursor: pointer; /* Change cursor to pointer on hover */
    outline: none; /* Remove focus outline */
    margin-right: 30px; /* Margin right for spacing */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  .baseButton:hover {
    background-color: #7fe0fe; /* Darker shade when hovered */
  }

  @keyframes flashing {
    0%, 55%, 100% { opacity: 1; }
    57.5%, 62.5% { opacity: 0; }
  }
  
