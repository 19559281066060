/* DispatchModal.css */

.dispatch-modal {
  position: fixed;
  z-index: 2000; /* Increased z-index */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 1000;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.dispatch-modal.open {
  opacity: 1;
  pointer-events: auto;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.modal-overlay.open-overlay {
  background-color: rgba(0, 0, 0, 0.0);
}

.modal-content {
  background-color:#ffffff ;
  border-radius: 10px;
  z-index: 1001;
  padding-left: 40px;
  padding-right: 40px;
  transition: transform 0.3s;
  transform: translateY(-50px);
  opacity: 0;
}

.modal-content.open-content {
  transform: translateY(0);
  opacity: 1;
    z-index: 1001;

}
